import * as styles from './index.module.scss';

import { useContext } from 'react';

import { formatPhoneNumber } from '@/helpers/helpers';
import { trackEvent } from '@/helpers/helpers_tracking';
import { SiteContext } from '@/contexts/site-context';

export const useTrackedPhoneNumber = () => {
  const [site] = useContext(SiteContext);

  const tryTrackEvent = () => {
    try {
      trackEvent({
        event_type: 'conversion',
        event_category: 'phone',
        event_action: 'click'
      });
      console.debug('event tracked');
    } catch (e) {
      console.debug('event not tracked', e);
    }
  };

  return { tryTrackEvent, phoneNumber: site.phone_number };
};

export const TrackedPhoneNumber = ({ className }: { className?: string }) => {
  const { tryTrackEvent, phoneNumber } = useTrackedPhoneNumber();

  if (!phoneNumber) return <span className={styles['placeholder']}></span>;

  return (
    <a className={`${styles['phone']} ${className || ''}`} href={`tel:${phoneNumber}`} onClick={tryTrackEvent}>
      {formatPhoneNumber(phoneNumber)}
    </a>
  );
};
