import * as styles from './index.module.scss';

import Link from 'next/link';
import { TrackedPhoneNumber } from '../TrackedPhoneNumber';

import PersonIcon from 'svgs/redesign/footer-24.svg';

const Footer = ({ category = { show_phone_in_header: true }, hideNavbar = false }) => {
  return (
    <footer className={styles['footer']}>
      <div className={`container ${styles['container']}`}>
        {!hideNavbar && (
          <div className={styles['nav-bar-container']}>
            {category.show_phone_in_header && (
              <div className={styles['cta-container']}>
                <div className={styles['icon-container']}>
                  <PersonIcon />
                </div>
                <div className={styles['content-container']}>
                  <p>Call For a Fast Quote</p>
                  <TrackedPhoneNumber />
                </div>
              </div>
            )}
            <div className={styles['columns-container']}>
              <Link href="/about">About Us</Link>
              <Link href="/accessible">Accessible View Page</Link>
              <Link href="/privacy-california/#do-not-sell">Do Not Sell My Info</Link>
              <Link href="/privacy-california">California Privacy Notice</Link>
              <Link href="/us">Sitemap</Link>
            </div>
          </div>
        )}
        <div className={styles['blurb']}>
          Call A Pro is a free service that helps homeowners connect with local service contractors. All contractors are independent and Call A Pro does not warrant or guarantee any work performed. It
          is the responsibility of the homeowner to verify that the contractor they hire has the necessary license and insurance required for the work being performed. eLocal Verified status only
          represents confirmation of a business&apos;s service area and business category (e.g., plumber).
        </div>
      </div>
      <div className={styles['copyright-container']}>
        <div className={`container ${styles['container']}`}>
          <div className={styles['copyright']}>
            &copy; {new Date().getFullYear()} <span>CallAPro Home Services</span>. All Rights Reserved.
          </div>
          <div className={styles['actions-container']}>
            <Link href="/signup" tabIndex={0}>
              Join our Network
            </Link>
            <Link href="/privacy" tabIndex={0}>
              Privacy Policy
            </Link>
            <Link href="/terms" tabIndex={0}>
              Terms &amp; Conditions
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
